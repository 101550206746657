let formSaisiechanged = false;

$("#form_saisie :input").change(function () {
    formSaisiechanged = true;
    $("a, button").click(function (e) {
        return saisieModal(formSaisiechanged, e)
    });
});

$('#user_navigation_structure').on('change', function (e) {
    if (formSaisiechanged === false) {
        redirectDropdown();
    }
    return saisieModal(formSaisiechanged, e)
});

function saisieModal(formSaisiechanged, event) {
    if (formSaisiechanged) {
        switch (event.target.name) {
            case 'onglet':
                break;
            case 'submit_saisie':
                break;
            default:
                $('#modalSaisie').show()
                $('#continueModalSaisie').on('click', function () {
                    $('#modalSaisie').hide()
                });
                $('#resetModalSaisie').on('click', function () {
                    document.getElementById("form_saisie").reset();
                    $('#modalSaisie').hide();
                    formSaisiechanged = false;
                    if (event.target.id === 'cancel_saisie') {
                        window.location.reload()
                    } else if (event.target.className === 'filter-option-inner-inner') {
                        redirectDropdown();
                    } else {
                        redirectSaisieModal(event.currentTarget)
                    }
                });
                return false;
        }
    }
}

function redirectDropdown() {
    let structureId = $('#user_navigation_structure')[0].value;
    if (!isNaN(structureId)) {
        if (window.location.href.includes('accueil')) {
            window.location.href = '/accueil/' + structureId;
        }
        if (window.location.href.includes('saisie')) {
            let href = window.location.href;
            let re = new RegExp("structureId=" + "\\s*(\\d+)");
            let m = href.match(re);
            if (m[1] != null) {
                window.location.href = href.replace("structureId=" + m[1], "structureId=" + structureId);
            }
        }
    }
}

function redirectSaisieModal(target) {
    targetHref = target.getAttribute('href');
    if (!targetHref) {
        location.reload();
        return false;
    }
    if (targetHref.includes('accueil')
        || targetHref.includes('saisie')
        || targetHref.includes('restitution')
        || targetHref.includes('consultation')
        || targetHref.includes('compte')) {
        window.location.href = targetHref;
    }
}

$("#submit_saisie").click(function () {
    if ($('#form_saisie')[0] === undefined) {
        return false;
    }
    if ($('#form_saisie')[0].checkValidity() === false) {
        $('#alert_danger_save_saisie').show(0).delay(2000).hide(0);
    } else if (formSaisiechanged === true) {
        saveSaisieModal(Object.values($("#form_saisie :input")));
        $('#alert_save_saisie').show(0).delay(2000).hide(0);
    }
    formSaisiechanged = false;
    return false;
});

function saveSaisieModal(formInputs) {
    let data = [];
    let date = null;
    let structureId = null;
    formInputs.forEach((item, index) => {
        if (item.name === 'date') {
            date = item.value;
        } else if (item.name === 'structure') {
            structureId = item.value;
        } else if (item.tagName === 'INPUT') {
            if (item.type === 'checkbox') {
                if (item.checked === true) {
                    data.push([item.name, 1]);
                } else {
                    data.push([item.name, 0]);
                }
            } else {
                data.push([item.name, item.value]);
            }
        }
    });
    $.post({
        type: 'POST',
        url: "/saveSaisie",
        data: {
            data: data,
            date: date,
            structureId: structureId
        },
        success: function (data) {
            return false;
        },
        error: function (error) {
        }
    });
}

$('.indicateur_multiple_plus').on('click', function (e) {
    targetId = e.currentTarget.id
    $('#' + targetId + '.indicateur_multiple_moins').css('display', 'inline-block');
    $('#' + targetId + '.indicateur_multiple_plus').css('display', 'none');
    $('#divMultiple' + targetId).css('display', 'block');
});

$('.indicateur_multiple_moins').on('click', function (e) {
    targetId = e.currentTarget.id
    $('#' + targetId + '.indicateur_multiple_plus').css('display', 'inline-block');
    $('#' + targetId + '.indicateur_multiple_moins').css('display', 'none');
    $('#divMultiple' + targetId).css('display', 'none');
});
