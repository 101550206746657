let adminRegion = $('#navigation_admin_Region');
adminRegion.change(function () {
    let form = $(this).closest('form');
    let data = {};
    data[adminRegion.attr('name')] = adminRegion.val();
    $.ajax({
        url: form.attr('action'),
        type: form.attr('method'),
        data: data,
        success: function (html) {
            $('#navigation_admin_Structure').replaceWith(
                $(html).find('#navigation_admin_Structure')
            );
        }
    });
});

let createAccountRegion = $('#ask_create_account_Structure_region');
createAccountRegion.change(function () {
    let form = $(this).closest('form');
    let data = {};
    data[createAccountRegion.attr('name')] = createAccountRegion.val();
    $.ajax({
        url: form.attr('action'),
        type: form.attr('method'),
        data: data,
        success: function (html) {
            $('#ask_create_account_Structure_structure').replaceWith(
                $(html).find('#ask_create_account_Structure_structure')
            );
        }
    });
});

$('#restitution_filter_Structure_StructureMultiple').attr('multiple', true);
$('#restitution_filter_Structure_StructureMultiple').attr('data-actions-box', true);
$('#restitution_filter_Structure_StructureMultiple').attr('title', "Choisissez une ou plusieurs structures");
$('#restitution_filter_Structure_StructureMultiple').attr('data-selected-text-format', "count");
$('#user_navigation_structure').attr('data-dropup-auto', "false");
$('#restitution_filter_Structure_StructureNational').attr('title', "Ensemble des structures de la région");
$('#restitution_filter_Structure_StructureNational').attr('multiple', true);
$('#restitution_filter_Structure_StructureNational').attr('data-actions-box', true);
$('#restitution_filter_Structure_StructureNational').attr('data-selected-text-format', "count");

$('#restitution_filter_Structure_Region').on('change', function () {
    let regionId = parseInt($(this).val());
    if (!isNaN(regionId)) {
        $.ajax({
            type: 'POST',
            url: "/getStructureByRegion",
            data: {
                regionId: regionId,
            },
        }).done(function (data) {
            new Promise((resolve, reject) => {
                let options = '';
                let structures = JSON.parse(data);
                for (let i = 0; i < structures.length; i++) {
                    let name = structures[i]['name'];
                    options += '<option value="' + structures[i]['id'] + '">    ' + name + '</option>';
                }
                resolve(options);
            }).then((options) => {
                $('#restitution_filter_Structure_StructureNational').empty().html(options).selectpicker('refresh');
            })
        })
    }
})

$('#restitution_filter_Structure_StructureNational').on('change', function () {
    let selectedStructures = $('#restitution_filter_Structure_StructureNational option:selected');
    let arrayInt = [];
    $(selectedStructures).each(function (index, structure) {
        arrayInt.push([structure.value, structure.text]);
    });
    $('#restitution_filter_Structure_SelectedStructure').val(JSON.stringify(arrayInt));
})

function getStructureByRegion(regionId) {
    $.ajax({
        type: 'POST',
        url: "/getStructureByRegion",
        data: {
            regionId: regionId,
        },
    }).done(function (data) {
        new Promise((resolve, reject) => {
            let options = '';
            let structures = JSON.parse(data);
            for (let i = 0; i < structures.length; i++) {
                let name = structures[i]['name'];
                options += '<option value="' + structures[i]['id'] + '">    ' + name + '</option>';
            }
            resolve(options);
        }).then((options) => {
            let data = $('#restitution_filter_Structure_SelectedStructure').val();
            let values = [];
            if (data) {
                let oldStructures = JSON.parse(data);
                oldStructures.forEach(function (element) {
                    values.push(element[0])
                })
            }
            $('#restitution_filter_Structure_StructureNational').empty().html(options).selectpicker('val', values).selectpicker('refresh');
        })
    });
}

$(document).ready(function () {
    let region = $('#restitution_filter_Structure_Region');
    let regionId = parseInt(region.val());
    if (!isNaN(regionId)) {
        getStructureByRegion(regionId)
    }
});
