const $ = require('jquery');
require('bootstrap');
require('bootstrap-select/dist/css/bootstrap-select.min.css');
require('bootstrap-select/dist/js/bootstrap-select.min');
require('bootstrap-select/dist/js/i18n/defaults-fr_FR.min')
require('bootstrap-datepicker/dist/js/bootstrap-datepicker.min');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min');
require('bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css');
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.min');
require('../js/images.js');
require('../js/datePicker.js');
require('../js/cookie.js');
require('../js/saisieModal.js');
require('../js/dropdown.js');

