$('#user_navigation_btn_saisie').on("click", function () {
    let date = $('#datepicker').datepicker('getDate');
    document.cookie = "date=" + date;
});

if (getCookie('date') === "") {
    let date = new Date();
    date.setDate(1);
    document.cookie = "date=" + date;
    $('#datepicker').datepicker('setDate', date);
} else {
    let date = new Date(getCookie('date'));
    $('#datepicker').datepicker('setDate', date);
}

$('#modalCGU').show()

$('#validateModalCGU').on('click', function () {
    setCgu(true, Date.now())
});

$('#refuseModalCGU').on('click', function () {
    setCgu(false, null)
});

function setCgu(isCguValidation, dateCguValidation, email) {
    $.ajax({
        type: 'POST',
        url: "/setCgu",
        data: {
            isCguValidation: isCguValidation,
            dateCguValidation: dateCguValidation,
            email: email
        },
        success: function (data) {
            if (isCguValidation === false) {
                window.location.href = "/logout"
            } else {
                window.location.href = "/"
            }
        },
        error: function (error) {
        }
    });
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}