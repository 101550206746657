$('#datepicker').datepicker({
    format: "mm/dd/yyyy",
    startView: 1,
    minViewMode: 1,
    maxViewMode: 2,
    language: "fr",
});

$('.restitutionDatepicker').datepicker({
    format: "mm/yyyy",
    startView: 1,
    minViewMode: 1,
    maxViewMode: 2,
    language: "fr",
    autoclose: true
});

if ($('#datepicker').length) {
    let year = new Date($('#datepicker').data('datepicker').getFormattedDate('yyyy-mm-dd')).getFullYear();
    let thisYear = new Date().getFullYear();
    if (year <= thisYear && year > 2018) {
        getYearData(year);
    }
}

$('#datepicker').datepicker()
    .on('changeDate', function () {
        structureId = null;
        if ($('#user_navigation_structure').length){
            structureId =  $('#user_navigation_structure')[0].value;
        }
        let year = new Date($('#datepicker').data('datepicker').getFormattedDate('yyyy-mm-dd')).getFullYear();
        let thisYear = new Date().getFullYear();
        if (year <= thisYear && year > 2018) {
            getYearData(year, structureId);
        }
    });

$('#datepicker').datepicker()
    .on('changeYear', function () {
        structureId = null;
        if ($('#user_navigation_structure').length){
            structureId =  $('#user_navigation_structure')[0].value;
        }
        let year = $('#datepicker').data('datepicker').viewDate.getFullYear();
        let thisYear = new Date().getFullYear();
        if (year <= thisYear && year > 2018) {
            getYearData(year, structureId);
        }
    });

function getYearData(year, structureId = null) {
    $.ajax({
        type: 'POST',
        url: "/getMonthState",
        data: {
            year: year,
            structureId: structureId
        },
        success: function (data) {
            colorCalendar(Object.values(JSON.parse(data)), year)
        },
        error: function (error) {
        }
    });
}

function colorCalendar(data, year) {
    let elements = $(".month");
    let thisMonth = new Date().getMonth();
    let thisYear = new Date().getFullYear();
    data.forEach((item, index) => {
        if (index > thisMonth && year >= thisYear) {
        } else if (item === "none") {
            elements[index].style.backgroundColor = "#bdbdbd"
        } else if (item === "partial") {
            elements[index].style.backgroundColor = "#ff9a18"
        } else if (item === "complete") {
            elements[index].style.backgroundColor = "#61a057"
        }
    });
}

$(document).ready(function () {
  $('#restitution_filter_applyFilter').on('click', function () {
    return verificationDate();
  });
  $('#restitution_filter_submitExport').on('click', function () {
    return verificationDate();
  });
});

function verificationDate () {
  $('#reversePeriod').hide();
  $('#oversizedPeriod').hide();
  let dateStart = $('#restitution_filter_datepickerStart').val();
  let dateEnd = $('#restitution_filter_datepickerEnd').val();
  dateStart = dateToYear(dateStart);
  dateEnd = dateToYear(dateEnd);
  if (dateStart > dateEnd) {
    $('#reversePeriod').show();
    return false;
  }
  if (Math.abs(dateEnd - dateStart) >= 1) {
    $('#oversizedPeriod').show();
    return false;
  }
}


function dateToYear (date) {
  let month = (date.split("/")[0]) - 1;
  let year = parseInt(date.split("/")[1]);
  return year + (month / 12);
}
